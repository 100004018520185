@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

@font-face {
  font-family: "CircularStd";
  src: url("/public/fonts/circular-std-font-family/CircularStd-Black.ttf")
    format("truetype");
  font-weight: 900;
  font-style: black;
}

@font-face {
  font-family: "CircularStd";
  src: url("/public/fonts/circular-std-font-family/CircularStd-Bold.ttf")
    format("truetype");
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: "CircularStd";
  src: url("/public/fonts/circular-std-font-family/CircularStd-Medium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: medium;
}

@font-face {
  font-family: "CircularStd";
  src: url("/public/fonts/circular-std-font-family/CircularStd-Book.ttf")
    format("truetype");
  font-weight: 450;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .padding {
    @apply max-w-7xl mx-auto px-[3%] py-10 lg:py-20;
  }

  .nav-item:not(.active) {
    @apply text-base font-medium relative after:content-[''] after:w-full after:h-[2px] after:block after:bg-gradient-to-r after:from-white after:to-primary-100 after:mt-1 after:absolute after:scale-x-0 hover:after:scale-x-100 after:transition after:duration-300 after:origin-left;
  }

  .nav-item.active {
    @apply text-primary-100 relative after:content-[''] after:w-full after:h-[2px] after:block after:bg-gradient-to-r after:from-white after:to-primary-100 after:mt-1 after:absolute;
  }

  .btn {
    @apply border border-primary-100 px-6 py-2 text-primary-100 font-medium hover:bg-primary-100/10 transition-all whitespace-nowrap;
  }

  .btn.primary {
    @apply text-white bg-primary-100 hover:bg-primary-100/90 flex items-center gap-2 px-4;
  }

  .card {
    @apply border border-primary-100 p-5 flex flex-col min-h-[360px];
  }

  .thumbnail {
    clippath: polygon(30% 0, 100% 0, 100% 100%, 0 100%, 0% 38%);
  }

  .promo {
    clippath: polygon(17% 1%, 100% 0, 100% 100%, 0 98%);
  }
}

@layer base {
  h1 {
    @apply text-5xl relative font-bold after:content-[''] after:w-64 after:h-[6px] after:block after:my-6 after:bg-gradient-to-r after:from-secondary-200 after:to-white leading-[60px];
  }

  h3 {
    @apply text-4xl font-bold after:content-[''] after:w-64 after:h-[6px] after:block after:my-6 after:bg-gradient-to-r after:from-secondary-200 after:to-white;
  }

  h4 {
    @apply text-3xl italic font-bold;
  }

  p {
    @apply text-base text-grey-200 font-[450] leading-6;
  }

  span {
    @apply text-xl font-medium text-primary-100;
  }

  a.learnmore {
    @apply text-primary-100 font-medium text-base;
  }
}
